<template>
  <div class="dF fC min-h-screen bh-layout">
    <air-topbar />
    <transition :name="settings.routerAnimation" mode="out-in">
      <router-view class="f1"/>
    </transition>
   
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AirTopbar from 'bh-mod/components/layout/TopBar'
export default {
  name: 'AppSetupLayout',
  computed: mapState(['settings']),
  components:{AirTopbar},
  data(){
    return {
      
    }
  }
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
  .bh-layout{
    background-position:top center;
    background-repeat: no-repeat
  }
</style>