<template>
	<a-layout-sider :width="'auto'" class="bh-menu-left">
		<span />
		<bar />
		<a href="javascript: void(0);" :class="$style.air__menuLeft__backdrop" @click="toggleMobileMenu" />
	</a-layout-sider>
</template>

<script>
import { mapState } from 'vuex'
import bar from '@/components/layout/sidebar.vue'

export default {
	name: 'menu-left',
	components: { bar },
	computed: {
		...mapState(['settings']),
		...mapState(['user']),
		flyoutActive() {
			return (this.settings.menuType === 'flyout' || this.settings.menuType === 'compact' || this.settings.isMenuCollapsed) && !this.settings.isMobileView
		},
		theApp() {
			return this.$store.state.theApp || {}
		},
		appData() {
			return this.$store.state.appData || {}
		},
	},

	data() {
		return {

			activeSubmenu: '',
			activeItem: '',
			renderedFlyoutItems: {},
			flyoutTimers: {},
			mode: 'inline',
			theme: 'light',
		}
	},
	watch: {
		'$route'() {
			// this.setActiveItems()
		},
	},

	methods: {

		toggleMobileMenu() {
			const setting = 'isMobileMenuOpen'
			const value = !this.settings[setting]
			this.$store.commit('CHANGE_SETTING', { setting, value })
		},
		toggleMenu() {
			const setting = 'isMenuCollapsed'
			const value = !this.settings[setting]
			this.$store.commit('CHANGE_SETTING', { setting, value })
		},
		toggleSettings() {
			const setting = 'isSidebarOpen'
			const value = !this.settings[setting]
			this.$store.commit('CHANGE_SETTING', { setting, value })
		},
		handleSubmenuClick(key) {
			const currentKey = this.activeSubmenu
			if (this.flyoutActive) {
				return
			}
			this.activeSubmenu = currentKey === key ? '' : key
		},

		handleFlyoutOver(event, key, items) {
			if (this.flyoutActive) {
				clearInterval(this.flyoutTimers[key])
				const item = event.currentTarget
				const itemDimensions = item.getBoundingClientRect()
				this.renderedFlyoutItems = {
					...this.renderedFlyoutItems,
					[key]: {
						key,
						itemDimensions,
						items,
					},
				}
			}
		},
		handleFlyoutOut(key) {

			if (this.flyoutActive) {
				this.flyoutTimers[key] = setTimeout(() => {
					const updatedFlyoutItems = Object.assign({}, this.renderedFlyoutItems)
					delete updatedFlyoutItems[key]
					this.renderedFlyoutItems = {
						...updatedFlyoutItems,
					}
				}, 100)
			}
		},
		handleFlyoutContainerOver(key) {
			clearInterval(this.flyoutTimers[key])
		},
	}
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
.bh-menu-left {
	top: 71px;
	height: calc(100vh - 71px);
	background-color: var(--white);

	.router-link-exact-active {
		color: var(--primary)
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: none;

		&:after {
			display: none;
		}
	}

	.ant-menu.ant-menu-inline.ant-menu-root.ant-menu-light {
		border-right: 0;
	}
}

.menu-left-heading {
	padding: 1.2rem 1.33rem;
	font-size: 18px;
	color: #40454c;
}
</style>
