import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    user: null,
    invites:[],
    showInvitation:false,
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user
    },
    UPDATE_INVITATIONS(state, data) {
      state.invites = data
    },
    ACCEPT_INVITE: (state,invite) => {
      state.invites = state.invites.filter(x => x.id !== invite.id)
    },
    SHOW_INVITATION(state) {
      state.showInvitation = true
    },
    HIDE_INVITATION(state) {
      state.showInvitation = false
    }
  },
  actions: {},
  getters: {
    user: state => state.user,
  },
}
