import store from '@/store'
import router from '@/router'
import {$auth, saveAuth} from 'bh-mod'
import { clearAuth } from 'bh-mod'


export default {
  install: (Vue, options) => {
    Vue.prototype.$auth = {
      login: (username, pass,fp) => {

        return new Promise((resolve, reject) => {
            $auth.post('/auth/local',{
              identifier:username,
              password:pass,
              renew:true
            }, {
              headers: {
                'x-fp':fp,
              }
            }).then( (res) => {
                let data = res.data
                if (data.t && data.value){
                  resolve({success:false,...data})
                } else if (data.jwt){
                  resolve({success:true,data})
                } else {
                  resolve({success:false})
                }
            }).catch( err => {
              console.error('ERROR', err)
              resolve({success:false, message:err})
            })

          
        })

        
        
      },
      logout: async () => {
        clearAuth(store)
        setTimeout(() => {
          router.push('/system/login', () => {})
        }, 200);
      },
    }
    // auth.onAuthStateChanged(user => {
    //   store.commit('UPDATE_USER', { user })
    // })
  },
}
