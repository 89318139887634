<template>
	<div class="w-full h-full dF fC aS" :class="sidebarItems[0].label === 'Workplace' ? 'apps-page-leftbar' : ''
		">
		<LeftNavBar @opened="clicked" :selectedValue="$route.path" :items="sidebarItems">
		</LeftNavBar>
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
	components: { LeftNavBar },
	data() {
		return {};
	},
	watch: {},
	computed: {
		sidebarItems() {
			if (this.$userType !== 'agent') {
				return [
					{
						label: "My Profile",
						value: "/settings/accounts",
						iconUrl: require("@/assets/sideicons/profile-icon.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Projects",
						value: "/settings/projects",
						iconUrl: require("@/assets/sideicons/project-icon.svg"),
						loading: false,
						options: [],
						children: [],
					},
					{
						label: "Billing",
						value: "/settings/billing",
						iconUrl: require("@/assets/sideicons/billing-icon.svg"),
						loading: false,
						options: [],
						children: [],
					},
				];
			} else {
				return [
					{
						label: "My Profile",
						value: "/settings/accounts",
						iconUrl: require("@/assets/sideicons/profile-icon.svg"),
						loading: false,
						options: [],
						children: [],
					}
				]
			}
		},
	},
	methods: {
		clicked(data) {
			const location = data.value;
			if (location != this.$route.path && location) {
				this.$router.push(location);
			}
		},
	},
	created() { },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
